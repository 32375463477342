.progress__container {
  width: 100%;
  text-align: center;
  margin-top: 30vh;
}

.progress__spinner {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 50%;
  border: 3px solid #E6E6FF;
  border-top: 3px solid #0000FF;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}

.progress__message {
  margin-top: 30px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}