.backdrop {
  width: 100%;
  height: 100%;
  background: rgba(256, 256, 256, 0.6);
  position: fixed;
  top: 0;
  left: 0;
}

.modal__card {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 390px;
  height: 278px;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.modal__body {
  margin: auto;
  margin-top: 40px;
  width: 234px;
}

.modal__card p {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.back__button {
  color: #080708;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

@media (max-width: 600px) {
  .modal__card {
    width: 311px;
    height: 307px;
  }
}