@import "./typography.css";
html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafbff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button {
  cursor: pointer;
}

.main__container {
  margin: 4% 8.54% 0;
}

.main__header {
  padding: 0 8.54%;
  width: 82.92%;
  line-height: 5;
  background: #FFFFFF;
}

.logo {
  width: 5.8125rem;
  height: 1.25rem;
}

.coming__soon {
  width: 42.375rem;
  margin: auto;
}

.privacy__policy__text {
  font-size: 1rem;
}

.privacy__policy__header {
  font-size: 1.5rem;
}

.coming__soon__heading {
  font-weight: bold;
  font-size: 2.625rem;
  line-height: 3.125rem;
  color: #0000ff;
}

.bold__message {
  font-weight: bold;
  font-size: 1.17738125rem;
  line-height: 1.4375rem;
  color: #080708;
  margin-top: 2.625rem;
}

.coming__soon>form {
  margin-top: 1.4375rem;
  width: 434px;
}

.coming__soon>p {
  font-size: 1rem;
  line-height: 1.625rem;
  color: #000000;
  font-weight: normal;
  margin-top: 2.625rem;
}

.default__input {
  width: 100%;
  height: 50px;
  border: 1px solid #6795ff;
  border-radius: 5px;
  padding: 0 20px;
  background: #fafbff;
}

.default__input:active, .default__input:focus {
  outline: none;
}

.default__input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6795ff;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  opacity: 1;
  /* Firefox */
}

.default__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6795ff;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}

.default__input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6795ff;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}

.form__body {
  margin-bottom: 20px;
}

.primary__button {
  width: 255px;
  height: 50px;
  border: 0px;
  background: #fa4343;
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.primary__button {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2.5;
}

.primary__button:active, .primary__button:focus {
  outline: none;
}

.footer__container {
  width: 100%;
  height: 279px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../assets/logo_watermark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-position-y: bottom;
  /* z-index: -9; */
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  background-color: #f0f0ff;
}

.footer__divider {
  height: 1px;
  background: #bed1ff;
  width: 77%;
  padding: 0;
  border: none;
}

.footer>section {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

.main__footer {
  margin: 0 8.54%;
  width: 82.92%;
  position: absolute;
  bottom: 0;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.main__footer>.socials {
  width: 92px;
  height: 22px;
  display: inline-block;
}

.icon__button {
  width: 27px;
  height: 21px;
  display: inline-block;
}

.twitter {
  background: url(../assets/twitter_logo.svg);
  display: inline-block;
  width: 27px;
  height: 23px;
}

.email {
  background: url(../assets/email_logo.svg);
  display: inline-block;
  width: 29px;
  height: 24px;
}

.timestamp {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 10px;
  color: #080708;
  float: right;
}

.link__button {
  background: none;
  outline: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  color: #828282;
  font-size: 16px;
  line-height: 18px;
}

.relative_position {
  position: relative !important;
}

.boring__link {
  text-decoration: none;
}

.boring__button {
  padding: 0;
  border: none;
  outline: none;
  background: none;
}

@media (min-width: 1800px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 2400px) {
  html {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 14px;
  }
  .main__container {
    margin: 2% 8.54% 0;
  }
}

@media (max-width: 1024px) {
  .footer__container {
    height: 110px;
  }
}

@media (max-width: 600px) {
  .main__header, .main__container {
    width: 94%;
    margin: 0 3%;
  }
  .main__header {
    padding: 0;
  }
  .coming__soon {
    width: 100%;
  }
  .coming__soon__heading {
    font-size: 1.854142857142857rem;
  }
  .coming__soon>form {
    width: 100%;
  }
  .default__input {
    padding: 0 1%;
    width: 98%;
  }
  .footer__container {
    position: initial;
    height: 60px;
    margin-top: 60px;
  }
  .main__footer {
    padding-bottom: 0px;
    width: 94%;
    margin: 0 3%;
    line-height: 4;
    position: initial;
  }
  .coming__soon__heading {
    margin-top: 10px;
  }
  .timestamp {
    line-height: inherit;
    height: 40px;
  }
  .main__footer>div:nth-child(2) {
    display: none;
  }
}

@media (min-height: 800px) {
  .footer__container {
    position: absolute;
  }
}