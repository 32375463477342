.home__wrapper {
  background: url(../assets/background.svg);
  /* background-attachment: fixed; */
  /* background-repeat: repeat; */
  background-position: center;
  background-size: 100%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.reduced__container {
  height: 660px;
}

.caption__container {
  width: 90%;
  max-width: 730px;
  height: min-content;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.main__caption {
  font-weight: 900;
  font-size: 3rem;
  line-height: 3.625rem;
  color: #0000FF;
  margin-bottom: 1.875rem;
}

.caption__container .primary__button {
  margin-top: 40px;
}

.description__container {
  width: 90%;
  max-width: 1110px;
  text-align: center;
}

.description__caption {
  font-weight: 900;
  font-size: 3rem;
  line-height: 3.625rem;
  text-align: center;
  color: #080708;
  margin-top: 0;
  margin-bottom: 1.875rem;
}

.description__sub__section {
  display: flex;
  justify-content: space-between;
}

.description__sub__section>div {
  width: 30%;
  max-width: 250px;
}

.userflow__presentation {
  height: 555px;
  border-radius: 8px;
  object-fit: fill;
}

.container.presentation {
  height: min-content;
  margin-top: 120px;
}

.presentation__container {
  padding-bottom: 120px;
  /* border-bottom: 1px solid #BED1FF; */
}

.container.closing {
  height: min-content;
  margin-bottom: 120px;
}

.footer__links {
  color: #080708;
  line-height: 1.625rem;
}

.footer__links:not(:last-of-type) {
  margin-right: 60px;
}

@media screen and (max-width: 600px) {
  .description__sub__section {
    flex-direction: column;
  }
  .description__sub__section>div {
    width: 80%;
    max-width: 80%;
    margin: auto;
    margin-bottom: 40px;
  }
  .content__creation__container {
    height: min-content;
  }
  .reduced__container {
    margin-top: -70px;
  }
  .userflow__presentation {
    height: unset;
    width: 100%;
  }
  .footer__links:not(:last-of-type) {
    margin-right: 10px;
  }
}