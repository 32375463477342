.navbar {
  display: flex;
  width: 100%;
  height: 120px;
  background-color: #ffffff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar__container {
  margin: 0 200px 0 200px;
}

.link {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: right;
  color: #0000FF;
  margin-right: 20px;
}

@media screen and (max-width: 600px) {
  .navbar__add__to__chrome {
    display: none!important;
  }
}